.faBox{background: white}
.center{width: 1000px;margin: 0 auto;}
.homeTop{
    height: 74px;
    line-height: 74px;
    position: fixed;
    width: 100%;
    background: white;
    z-index: 9999999;
}
.homeTop .leftBox a{
    display: inline-block;margin-right: 25px;color: #323232;
}
.homeTop .phone{color: #1a9afd; font-size: 18px; margin-right : 10px }
.homeTop .leftBox  .active{color: #1a9afd}
.homeTop .leftBox  .box{display: inline-block}
.homeTop img{height: 20px;margin-top: 25px;margin-right: 100px;}
.homeTop .gray{color: #E5E8ED;margin: 0 25px}
.homeTop .loginBtn{color: #1890FF !important;margin-right: 0 !important;}
.banner{height: 550px;text-align: center;overflow: hidden;position: relative;}
.banner .center{position: absolute;width: 100%;top: 0;}
.banner img{width: 100%;height: 100%;}
.banner h1{color: white;font-size: 48px;margin-top: 162px}
.banner h3{color: white;font-size: 28px;}
.banner a{display: inline-block;width:197px;height:56px;line-height: 56px;text-align: center;margin-top: 53px;
    color:rgba(24,144,255,1);background:rgba(255,255,255,1);
    border-radius:28px;
    border:1px solid rgba(47,155,255,1);}
#product {
    min-height: 720px;
    text-align: center;
    position: relative;
    background: white;
}
#product h1{
    padding-top: 137px;font-size:40px;margin-bottom: 50px;
}
#product .box{text-align: left;margin-bottom: 80px}
#product .box h3{
   font-size: 30px
}
#product .box hr{
    width:60px;
    height:4px;
    background:rgba(64,157,253,1);
    margin: 0;
    border: none;
}
#product .box p{color:rgba(50,50,50,0.65);line-height:26px;margin-top: 18px;text-align: justify}
#product .box a {display: block;width:122px;height:46px;border:1px solid rgba(24,144,255,1);text-align: center;line-height: 46px;
    margin-top: 38px;transition: .2s}
#product .box a:hover{
    background: #1a9afd;color: white;
}
#product .box .imgBox{
    width: 45%;
}
#product .left , #product .right{width: 45%;}
#our{background: white;overflow: hidden;text-align: center}
#solution{text-align: center;background:rgba(248,249,251,1);overflow: hidden;padding-bottom: 113px}
#solution h1{
    font-size:40px;padding-top: 109px;margin-bottom: 51px;
}
#solution .box{width: 310px;display: inline-block;height:538px;
    box-shadow:0px 2px 18px 0px rgba(173,205,236,0.29);margin-right: 20px;overflow: hidden}
#solution .box .topBox{
    height:140px;
    background:linear-gradient(225deg,rgba(39,118,255,1) 0%,rgba(110,198,255,1) 100%);
    padding: 36px 20px;
    color: white;
    text-align: left;
}
#solution .box .topBox h3{color: white;font-size:20px;}
#solution .bottom{background: white;padding: 30px;text-align: left;height: 400px}
#solution .bottom h3{margin-bottom: 12px}
#solution .bottom .sp{margin-left: 14px}
#solution .bottom .sp p {font-size:14px;color:rgba(111,114,133,1);margin-bottom: 10px;font-weight: lighter}
#solution .bottom .biao{width: 16px;margin-top: 5px}
#solution .box .topBox p{text-align: justify;font-size:14px;display: -webkit-box;
    -webkit-line-clamp:2;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;}
#solution .carou{
    width:40px;
    height:160px;
    background:rgba(25,40,55,0.1);
    text-align: center;
    line-height: 160px;
    font-size: 20px;color: white;
    cursor: pointer;
    position: absolute;
}
#solution .leftCarou{position: absolute;left: -50px;top:50%;}
#solution .rightCarou{position: absolute;right: -50px;top:50%;}
#solution .center{
    position: relative;
}
#solution .carou:hover span{color: #409DFD}
#solution .pBox .box:nth-last-child(1){margin-right: 0}
.page1 {
    min-height: 500px;
}
.page1 .page > h1 {
    margin-top: 110px;
    font-size:40px;
}
.page1-item {
    text-align: center;
    margin-top: 96px;
}
.page1-item-link {
    display: block;
    width: 100%;
    padding: 50px 0;
}
.page1-item-link:hover{
    box-shadow:0px 0px 30px 0px rgba(40,52,68,0.06);
    border-radius:2px;
    border-bottom: 4px solid #1890FF;
}
.page1-item-img img {
    height: 90px;
}
.page1-item-img {
    height: 100px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    box-shadow: none !important;
    margin: 0 auto;
}
.page1-item-title {
    margin-top: 21px;
    font-size: 20px;
    color: #0D1A26;
}
.page1-item p {
    color: #697B8C;
    margin-top: 8px;
}
.page1 .point-wrapper {
    position: absolute;
    width: 0;
    left: 50%;
    top: 0;
    z-index: 0;
}
.page1 .point-wrapper .point-0 {
    width: 7px;
    height: 7px;
}
.page1 .point-wrapper .point-2 {
    width: 10px;
    height: 10px;
}
.page1 .point-wrapper .point-ring {
    width: 12px;
    height: 12px;
    border-style: solid;
    border-width: 1px;
    background: transparent !important;
}
.page1 .point-wrapper .point-ring-0 {
    width: 7px;
    height: 7px;
}
.page1 .point-wrapper .point-1 {
    width: 12px;
    height: 12px;
}
.page1 .point-wrapper .point-3 {
    width: 15px;
    height: 15px;
}
.page1 .point-wrapper i {
    display: inline-block;
    border-radius: 100%;
    position: absolute;
    opacity: 0;
    transform: translate(0, 30px);
}
.orange{
    color: #FA8C18 !important;
}
