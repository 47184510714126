.screen {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.screen .screen-skeleton {
  width: 100vw;
  height: 100vh;
}

.screen .screen-spin.ant-spin {
  max-height: none;
}
