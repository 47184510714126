li {
  list-style-type: none;
}
ul {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  padding-inline-start: 0 !important;
}
body {
  // overflow: auto;
  background-color: #f0f2f5;
}
.container {
  width: 100%;
  min-width: 1000px;
  font-family:PingFangSC-Regular,PingFang SC;
  .headerCon {
    left: 0;
    top: 0;
    position: fixed;
    width: 100%;
    z-index: 1000001;
    background: #fff;
  }
  .header {
    height: 64px;
    max-width: 1000px;
    margin: 0 auto;
    background: #fff;
    .logo {
      display: inline-block;
      height: 64px;
      width: 114px;
      margin-right: 82px;
    }
    .topNav {
      display: inline-block;
      margin-right: 90px;
      height: 64px;
      line-height: 64px;
      a {
        margin-right: 40px;
        color: #323232;
        font-size:14px;
        &.active {
          color: #1890FF;
        }
        &:hover {
          color: #1890FF;
        }
      }
    }
    .line {
      display: inline-block;
      width: 1px;
      height: 14px;
      background: #E5E8ED;
      margin-top: 25px;
      margin-right: 14px;
    }
    a {
      color: #1890FF;
    }
  }
  .visitBtn {
    display: inline-block;
    width: 197px;
    height: 56px;
    line-height: 56px;
    text-align: center;
    color: #fff;
    border-radius: 28px;
    background-color: #1890FF;
  }
  .section4 {
    height: 756px;
    width: 100%;
    background-size: 100% 756px;
    background-repeat: no-repeat;
    background-image: url('/img/bg3.png');
    display: flex;
    align-items: center;
    h2 {
      color: #fff;
      font-size: 40px;
      margin-bottom: 50px;
      text-align: center;
    }

    .partner {
      display: inline-block;
      text-align: center;
      margin-right: 40px;
      &:hover {
        background-color: transparent !important;
        border-bottom: 0;
        }
      &:last-child {
        margin-right: 0;
      }
      img {
        margin: 0 auto 12px;
      }
      h4 {
        text-align: center;
        font-size: 24px;
        color: #fff;
        margin-bottom: 15px;
      }
      .line {
        width: 190px;
        height: 1px;
        background: rgba(255,255,255,.28);
        margin-bottom: 18px;
      }
      ul li {
        color: #fff;
        font-size: 18px;
        margin-bottom: 22px;
        text-align: left;
        padding-left: 14px;
        span {
          &::before{
            content: "";
            display: inline-block;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: #fff;
            vertical-align: middle;
            margin-right: 20px;
          }
        }
      }
    }
  }


  .section7 {
    background:#fff;
    padding: 110px 0;
    h2 {
      font-size: 40px;
      color: #000;
      margin-bottom: 50px;
      text-align: center;
    }
    ul{padding-left: 0;}
    .poster {
      display: inline-block;
      width: 430px;
      margin-right: 20px;
      box-shadow:0px 0px 12px 0px rgba(173,205,236,0.29);
      border-radius:4px;
      border:1px solid rgba(240,240,243,1);
      &:last-child {
        margin-right: 0;
      }
      .header {
        position: relative;
        height: 210px;
        line-height: 210px;
        text-align: center;
        border-bottom: 1px solid #F0F0F3;
        h3 {
          font-size: 40px;
        }
        .iconBox {
          position: absolute;
          width: 140px;
          height: 50px;
          line-height: 50px;
          text-align: center;
          border-radius: 30px;
          left: 50%;
          bottom: 0;
          transform: translate(-50%, 50%);
          z-index: 1001;
          border:1px solid rgba(240,240,243,1);
          font-size: 0;
        }
      }
      .body {
        box-sizing: border-box;
        padding-top: 90px;
        padding-left: 155px;
        height: 380px;
        li {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .btn {
        cursor: pointer;
        height: 69px;
        line-height: 69px;
        text-align: center;
        color: #fff;
        font-size: 18px;
      }
    }
    .free {
      .header{
        h3 {
          color: #000;
        }
        .iconBox {
          background: #F0F0F3;
        }
      };
      .btn {
        background:linear-gradient(360deg,rgba(53,195,255,1) 0%,rgba(24,144,255,1) 100%);
      }
    }
    .vip {
      .header {
        background-size: 430px 210px;
        background-repeat: no-repeat;
        background-image: url('/img/bg4.png');
        h3 {
          color: #fff;
        }
        .iconBox {
          background: #fff;
        }
      }
      .btn {
        background:linear-gradient(360deg,rgba(135,114,252,1) 0%,rgba(112,88,241,1) 100%);
      }
    }
  }


  .center {
    padding-top: 1px;
    height: 100%;
    margin: 0 auto;
    clear: both;
  }

  .panel {
    padding-left: 90px;
    h2 {
      color: #fff;
      font-size: 48px;
      color: #fff;
      margin-bottom: 10px;
    }
    h3 {
      font-size: 26px;
      color: #fff;
      margin-bottom: 50px;
    }
    >div {
      box-sizing: border-box;
      width: 450px;
      background: rgba(0, 0, 0, .2);
      padding: 45px;
      color: #fff;
      line-height: 2em;
    }
  }

  .windowCon {
    display: inline-block;
    box-sizing: border-box;
    vertical-align: top;
    width: 280px;
    height: 340px;
    padding: 20px;
    background:rgba(255,255,255,1);
    border-radius:2px;
    cursor: pointer;
    transition: all 0.2s;
    margin-right: 20px;
    &:last-child {
      margin-right: 0px;
    }
    &:hover {
      transform: translateY(-20px);
      box-shadow:0px 0px 30px 0px rgba(40,52,68,0.06);
      border-bottom: 4px solid #1890FF;
    }
    h3 {
      margin-bottom: 20px;
    }
    p {
      color: #85889E;
    }
  }
}

.flexCenter {
  display: flex;
  align-items: center;
}

.flexHCenter {
  display: flex;
  justify-content: center;
}

.contact {
  background: #F8F9FB;
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: center;
  .box {
    margin: 0px 100px;
    .BoxTip1 {
      font-weight: 400;
      color: rgba(111, 114, 133, 1);
      line-height: 30px;
    }
    .BoxTip2 {
      font-weight: 500;
      color: rgba(84, 101, 118, 1);
      line-height: 30px;
    }
    .BoxTip3 {
      margin-top: 10px;
      font-weight: 500;
      color: rgba(36, 201, 127, 1);
      line-height: 30px;
    }
  }
}

.title {
  font-size: 40px;
  color: #000;
  margin-bottom: 50px;
}
.content {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.footer {
  height: 257px;
  background: #33373b;
  .content {
    width: 1100px;
    margin: auto;
    height: 100%;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    .item1 {
      color: #fff;
      font-size: 16px;
      opacity: 0.9;
      padding-top: 60px;
    }
    .item {
      padding-top: 54px;
      p {
        color: #fff;
        display: block;
        opacity: 0.6;
        margin-bottom: 17px;
      }
      .bigTitle {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 22px;
        opacity: 1;
      }
      a {
        display: block;
        font-weight: 500;
        color: #4bacff;
        opacity: 0.9;
        margin-bottom: 17px;
      }
    }
  }
}
.spFooter{
  background: #0E1121;text-align: center;height: 50px;line-height: 50px;
  a{color: white;}
}
.fouBox {
  text-align: center;
  width: 700px;
  height: 477px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  img {
    width: 645px;
  }
  p {
    font-size: 18px;
    color: #ef3b27;
    margin-top: 41px;
    margin-bottom: 85px;
  }
  a {
    display: inline-block;
    width: 160px;
    height: 48px;
    border: 1px solid #b1b1b1;
    border-radius: 31px;
    text-align: center;
    line-height: 48px;
    color: #535455;
    font-size: 18px;
  }
}